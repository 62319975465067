<template>
<!-- 經銷商 -->
  <div class="agency">
    <JoinUsFrame>
      <template v-slot:leftIcon>
        <LeftBar :page="page">
          <template v-slot:spanBox>
            <div class="divBox">
              <div class="centerLine">
                <div class="span">
                  <span>{{leftBarTittle}}</span>
                </div>
                <div class="span">
                  <span>{{leftBarTextTwo}}</span>
                </div>
                <div class="span">
                  <span>遇上問題? 技術支持</span>
                </div>
                <div class="span">
                  <span>關於隱私</span>
                </div>
                <div class="span">
                  <span>知識產權</span>
                </div>
                <div class="span">
                  <span>網路安全法</span>
                </div>
              </div>
            </div>
          </template>
        </LeftBar>
      </template>
      <template v-slot:topIcon>
        <NumberNavBar :page="page" :allPage="allPage">
          <template v-slot:1>
            <span>Personal information1</span>
          </template>
          <template v-slot:7>
            <span>About you1</span>
          </template>
          <template v-slot:2>
            <span>Personal information2</span>
          </template>
          <template v-slot:8>
            <span>About you2</span>
          </template>
          <template v-slot:3>
            <span>Personal information3</span>
          </template>
          <template v-slot:9>
            <span>About you3</span>
          </template>
          <template v-slot:4>
            <span>Personal information4</span>
          </template>
          <template v-slot:10>
            <span>About you4</span>
          </template>
          <template v-slot:5>
            <span>Personal information5</span>
          </template>
          <template v-slot:11>
            <span>About you5</span>
          </template>
          <template v-slot:6>
            <span>Personal information6</span>
          </template>
          <template v-slot:12>
            <span>About you6</span>
          </template>
        </NumberNavBar>
      </template>
      <template v-slot:pageForm>
        <div class="pageForm">
          <AgencyFormOne v-if="page==1" @isFormOk="isFormOneOk" :chickShow="pageChickShow"></AgencyFormOne>
          <AgencyFormTwo v-if="page==2" @isFormOk="isFormTwoOk" @form="pageTwoForm" :chickShow="pageChickShow"></AgencyFormTwo>
          <AgencyFormThree v-if="page==3" @isFormOk="isFormThreeOk" @form="pageThreeForm" :chickShow="pageChickShow"></AgencyFormThree>
          <AgencyFormFour v-if="page==4" @isFormOk="isFormFourOk" @form="pageFourForm" :chickShow="pageChickShow"></AgencyFormFour>
          <!-- <AgencyFormFive v-if="page==5"></AgencyFormFive> -->
          <AgencyFormSix v-if="page==5"></AgencyFormSix>
        </div>
      </template>
      <template v-slot:backIcon>
        <div class="btnBack" v-show="btnBackShow">
          <BtnSubmit color="red" @click="goBack()">{{btnBack}}</BtnSubmit>
        </div>
      </template>
      <template v-slot:nextIcon>
        <div class="btnNext" v-show="btnNextShow">
          <BtnSubmit @click="goNext()">{{btnNext}}</BtnSubmit>
        </div>
      </template>
    </JoinUsFrame>
  </div>
</template>

<script>
import JoinUsFrame from '@/views/login/joinUs/components/joinUsFrame/JoinUsFrame'
import BtnSubmit from '@/components/button/BtnSubmit'
import AgencyFormOne from '@/views/login/joinUs/agency/agencyPage/AgencyFormOne'
import AgencyFormTwo from '@/views/login/joinUs/agency/agencyPage/AgencyFormTwo'
import AgencyFormThree from '@/views/login/joinUs/agency/agencyPage/AgencyFormThree'
import AgencyFormFour from '@/views/login/joinUs/agency/agencyPage/AgencyFormFour'
// import AgencyFormFive from '@/views/login/joinUs/agency/agencyPage/AgencyFormFive'
import AgencyFormSix from '@/views/login/joinUs/agency/agencyPage/AgencyFormSix'
import LeftBar from '@/views/login/joinUs/components/leftBar/LeftBar'
import NumberNavBar from '@/views/login/joinUs/components/numberNavBar/NumberNavBar'
import {ProveImageController,AgencyPost} from "@/axios/api/dict"
export default {
  name:'Agency',
  components: {
    JoinUsFrame,
    BtnSubmit,
    AgencyFormOne,
    AgencyFormTwo,
    AgencyFormThree,
    AgencyFormFour,
    // AgencyFormFive,
    AgencyFormSix,
    LeftBar,
    NumberNavBar,
  },
  data() {
    return {
      btnBack:'Back',
      btnNext:'Next',
      page:1,
      allPage:5,
      btnBackShow:false,
      btnNextShow:true,

      //LeftBar
      leftBarTittle:"免責聲明",
      leftBarTextTwo:"提供虛假信息是非法行為，Finger可以隨時停止您的服務",

      //表單Warring顯示
      pageChickShow:false,

      //換頁面Boolen
      nextBoolen:false,

      //要Post出去的表單
      postForm:{ //http://13.114.63.198:8088/api/crm/agent/apply-agent
        agentNumber: "",
        nationality: "",
        companyType: "",
        agentType: "",
        name: "",
        description: "",
        address: "",
        zipCode: "",
        city: "",
        district: "",
        website: "",
        phone: "",
        taxCountry: "",
        taxNumber: "",
        companyCertificateId: "",
        taxCertificateId: "",
        licenseId: "",
        licenseIdTwo: "",
        
      },
      licenseIdThree: "",

      postForm2:{ //http://13.114.63.198:8088/api/crm/prove-image/save-prove-img
        fileCountry:"",
        fileType: "",
        issuingAuthority:"",
        periodValidity:"",
        imageUrl: "",
      },
      postForm3:{ //http://13.114.63.198:8088/api/crm/prove-image/save-prove-img
        fileCountry:"",
        fileType: "",
        issuingAuthority:"",
        periodValidity:"",
        imageUrl: "",
      },
      postForm4:{ //http://13.114.63.198:8088/api/crm/prove-image/save-prove-img
        fileCountry:"",
        fileType: "",
        issuingAuthority:"",
        periodValidity:"",
        imageUrl: "",
      },
    }
  },
  methods:{
    goBack(){
      if(this.page > 1){
        this.page--
      }
    },
    goNext(){
      if(this.nextBoolen == true && this.page == 4){
        ProveImageController(this.postForm2).then(res =>{
          if(res.code == 20000){
            this.postForm.licenseId = res.data.proveImage
          }
          else{
            console.log(res.message)
          }
        })
        ProveImageController(this.postForm3).then(res =>{
          if(res.code == 20000){
            this.postForm.licenseIdTwo = res.data.proveImage
          }
          else{
            console.log(res.message)
          }
        })
        ProveImageController(this.postForm4).then(res =>{
          if(res.code == 20000){
            this.licenseIdThree = res.data.proveImage
          }
          else{
            console.log(res.message)
          }
        })
        AgencyPost(this.postForm).then(res =>{
          console.log(this.postForm)
          console.log(res)
        })
        
      }

      if(this.nextBoolen == true && this.page < this.allPage){
        this.page++
        this.nextBoolen = false
        this.pageChickShow = false
      }
      else{
        this.pageChickShow = true
      }

      console.log(this.postForm)
    },
    btnShow(){
      if(this.page == 1){
        this.btnBackShow = false
        this.btnNextShow = true
        this.btnNext = "Next"
      }
      else if(this.page < this.allPage){
        this.btnBackShow = true
        this.btnNextShow = true
        this.btnNext = "Next"
      }
      else{
        this.btnNext = "Finish"
      }
    },
    isFormOneOk(CheckBoolen){
      if(CheckBoolen == true){
        this.nextBoolen = true
      }
      else{
        this.nextBoolen = false
      }
    },
    isFormTwoOk(CheckBoolen){
      if(CheckBoolen == true){
        this.nextBoolen = true
      }
      else{
        this.nextBoolen = false
      }
    },
    isFormThreeOk(CheckBoolen){
      if(CheckBoolen == true){
        this.nextBoolen = true
      }
      else{
        this.nextBoolen = false
      }
    },
     isFormFourOk(CheckBoolen){
      if(CheckBoolen == true){
        this.nextBoolen = true
      }
      else{
        this.nextBoolen = false
      }
    },
    pageTwoForm(form){
      this.postForm.agentNumber = form.meadowviewDrive
      this.postForm.nationality = form.country
      this.postForm.companyType = form.organizationType
      this.postForm.agentType = form.brokerType
    },
    pageThreeForm(form){
      this.postForm.name = form.companyName
      this.postForm.description = form.description
      this.postForm.address = form.officeAddress
      this.postForm.zipCode = form.zip
      this.postForm.city = form.city
      this.postForm.district = form.state
      this.postForm.website = form.internetAddress
      this.postForm.phone = form.phoneTitle + "_" + form.phoneNumber
      this.postForm.taxCountry = form.taxResidence
      this.postForm.taxNumber = form.taxNumber
      this.postForm.companyCertificateId = form.imgUrl1
      this.postForm.taxCertificateId = form.imgUrl2
    },
    pageFourForm(form){
      this.postForm2.fileCountry = form.countryName
      this.postForm2.fileType = form.brokerType
      this.postForm2.issuingAuthority = form.issuingAuthorityName
      this.postForm2.periodValidity = form.licenseNumber
      this.postForm2.imageUrl = form.imgUrl1

      this.postForm3.fileCountry = form.countryNameAnalyst
      this.postForm3.fileType = form.brokerTypeAnalyst
      this.postForm3.issuingAuthority = form.issuingAuthorityNameAnalyst
      this.postForm3.periodValidity = form.licenseNumberAnalyst
      this.postForm3.imageUrl = form.imgUrl2

      this.postForm4.fileCountry = form.countryNameOther
      this.postForm4.fileType = form.brokerTypeOther
      this.postForm4.issuingAuthority = form.issuingAuthorityNameOther
      this.postForm4.periodValidity = form.licenseNumberOther
      this.postForm4.imageUrl = form.imgUrl3
    },
  },
  watch:{
    "page"(){
      this.btnShow()
      // console.log(this.page)
      if(this.page == 1){
        this.leftBarTittle = "監管聲明"
        this.leftBarTextTwo = "提供虛假信息是非法行為，Finger可以隨時停止您的服務"
      }
      else if(this.page == 2){
        this.leftBarTittle = "個人資訊"
        this.leftBarTextTwo = "請輸入您的個人訊息，並確保這些資料為真實訊息，讓我們方便為您提供接下來的服務。"
      }
      else if(this.page == 3){
        this.leftBarTittle = "機構訊息"
        this.leftBarTextTwo = "請輸入您的個人訊息，並確保這些資料為真實訊息，讓我們方便為您提供接下來的服務。"
      }
      else if(this.page == 4){
        this.leftBarTittle = "牌照信息"
        this.leftBarTextTwo = "如果想要介绍交易期货或期货期权的账户，您必须是注册期货佣金商或介绍经纪商。如果想要介绍交易现金外汇的账户，您必须是注册期货佣金商、介绍经纪商、零售外汇交易商或证券经纪交易商。"
      }
      else if(this.page == 5){
        this.leftBarTittle = "資格證書"
        this.leftBarTextTwo = "請輸入您的個人訊息，並確保這些資料為真實訊息，讓我們方便為您提供接下來的服務。"
      }
      else{
        this.leftBarTittle = "個人資訊"
        this.leftBarTextTwo = "請輸入您的個人訊息，並確保這些資料為真實訊息，讓我們方便為您提供接下來的服務。"
      }
    }
  }
}
</script>

<style scoped>
  .agency{
    width: 100%;
    height: 100%;
    background: white;
  }
  .btnBack{
    position: absolute;
    left: 210px;
    top: 0;
  }
  .btnNext{
    position: absolute;
    right: 210px;
    top: 0;
  }
  .pageForm{
    width: 100%;
    height: 100%;
  }

  /*LeftBar*/
  .divBox{
    width: 100%;
    height: 100%;
    /* border: 1px rgb(102, 255, 0) solid; */
    display: flex;
  }
  .centerLine{
    height: 100%;
    /* border: 1px rgb(102, 255, 0) solid; */
    position: relative;
  }
  .span{
    font-family: Inter;
    font-style: normal;
    color: #FFFFFF;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    margin-left: 40px;
  }
  .span:nth-child(1){
    font-size: 72px;
    font-weight: 800;
    margin-top: 50px;
    margin-bottom: 36px;
  }
  .span:nth-child(2){
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    margin-bottom: 36px;
    max-width: 300px;
  }
  .span:nth-child(3){
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: center;
    cursor: pointer;
    margin-bottom: 255px;
  }
  .span:nth-child(3):hover{
    color: #0099ff;
    font-size: 20px;
  }
  .span:nth-child(4),.span:nth-child(5),.span:nth-child(6){
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 20px;
    cursor: pointer;
  }
  .span:nth-child(4):hover,.span:nth-child(5):hover,.span:nth-child(6):hover{
    color: #0099ff;
    font-size: 20px;
  }
</style>
