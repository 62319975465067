<template>
<div>
  <div class="agencyFormOne">
    <div class="leftAndRightBox">
      <div class="centerOutBox">
        <div class="spanBox">
          <span>Finger免責聲明</span>
        </div>
      </div>
      <div class="centerOutBox">
        <div class="spanBox">
          <span>軟體營銷</span>
        </div>
      </div>
      <div class="centerOutBox">
        <div class="spanBox">
          <span>Finger為一家軟體公司，僅給全球金融機構提供軟體服務，不涉及任何交易，Finger內的交易都是透過各國金融服務機構提供，軟體經銷商不涉及任何的金融商品推廣及營銷，我們也不提供除軟體服務以外的任何結算及獎勵。</span>
        </div>
      </div>
      <div class="centerOutBox">
        <div class="spanBox">
          <span>券商經銷商</span>
        </div>
      </div>
      <div class="centerOutBox">
        <div class="spanBox">
          <span>如果您是隸屬於已在Finger註冊的券商經銷商，請輸入券商的券商編號，並在您所在地法律允許下提供服務，Finger不負任何法律責任。</span>
        </div>
      </div>
      <div class="centerOutBox">
        <div class="spanBox">
          <span>請選擇符合您的條件</span>
        </div>
      </div>
      <div class="centerOutBox">
        <div class="centerInBox">
          <div class="spanBox2">
            <input class="checkbox" type="checkbox" v-model="inputValue1">
          </div>
          <div class="spanBox2">
            <span>您是否有该申请适用于未在任何监管机构获批或注册的经纪公司。</span>
          </div>
        </div>
      </div>
      <div class="centerOutBox">
        <div class="centerInBox">
          <div class="spanBox2">
            <input class="checkbox" type="checkbox" v-model="inputValue2">
          </div>
          <div class="spanBox2">
            <span>该申请适用于在单个国家获批或注册的经纪公司。</span>
          </div>
        </div>
      </div>
      <div class="centerOutBox">
        <div class="centerInBox">
          <div class="spanBox2">
            <input class="checkbox" type="checkbox" v-model="inputValue3">
          </div>
          <div class="spanBox2">
            <span>该申请适用于在多个国家获批或注册的经纪公司。</span>
          </div>
        </div>
      </div>
      <div class="centerOutBox">
        <div class="centerInBox">
          <div class="spanBox2">
            <input class="checkbox" type="checkbox" v-model="inputValue4">
          </div>
          <div class="spanBox2">
            <span>主要注册/许可国家或地区</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="warringText" v-if="chickShow">
    <span>{{warring}}</span>
  </div>
</div>
</template>

<script>
export default {
  name:'AgencyFormOne',
  components: {

  },
  props:{
    chickShow:{
      type:Boolean,
      default:false
    }
  },
  data() {
    return {
      inputValue1:false,
      inputValue2:false,
      inputValue3:false,
      inputValue4:false,
      isFormOk:false,
      warring:"*You need to chick it all"
    }
  },
  methods:{
    changPage(){
      if(this.inputValue1 == true 
      && this.inputValue2 == true 
      && this.inputValue3 == true 
      && this.inputValue4 == true ){
        this.isFormOk = true
        this.warring = ""
        this.$emit('isFormOk', this.isFormOk)
      }
      else{
        this.isFormOk = false
        this.warring = "*You need to chick it all"
        this.$emit('isFormOk', this.isFormOk)
      }
    }
  },
  watch:{
    "inputValue1"(){
      this.changPage()
    },
    "inputValue2"(){
      this.changPage()
    },
    "inputValue3"(){
      this.changPage()
    },
    "inputValue4"(){
      this.changPage()
    },
  }
}
</script>

<style scoped>
*{
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  display: block;
  line-height: 1;
  list-style: none;
  quotes: none;
  content: '';
  content: none;
  border-collapse: collapse;
  border-spacing: 0;
}
.agencyFormOne{
  width: 100%;
  height: 100%;
  display: flex;
}
.leftAndRightBox{
  margin:0 auto;
  max-width: 847px;
}
.spanBox{
  margin:0 auto;
}
.checkbox{
  height: 24px;
  width: 24px;
}
.centerInBox{
  margin:0 auto;
  display: flex;
  font-family: Inter;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  width: 670px;
  margin-bottom: 5px;
}
.centerOutBox{
  display: flex;
  font-family: Inter;
  font-style: normal;
  font-weight: 700;
  
}
.centerOutBox:nth-child(1){
  font-size: 38px;
  margin-top: 47px;
  margin-bottom: 38px;
}
.centerOutBox:nth-child(2), .centerOutBox:nth-child(4), .centerOutBox:nth-child(6){
  font-size: 28px;
  margin-bottom: 16px;
}
.centerOutBox:nth-child(3), .centerOutBox:nth-child(5){
  font-size: 22px;
  margin-bottom: 50px;
}
.warringText{
  color: rgb(255, 0, 0);
  text-align: center;
}
</style>
