<template>
  <div class="agencyFormTwo">
    <div class="textBigBox">
        <div class="item">
          <span>券商行號</span>
          <span>(非必填)</span>
        </div>
        <div class="item">
          <span>
            如果您是隸屬於已在Finger註冊的券商經銷商，請輸入券商的券商編號。
          </span>
        </div>
        <div class="item">
          <Validator type="notRequired" type2="number" :value="form.meadowviewDrive" :show="chickShow" @isCheckOkValue="meadowviewDriveCheck">
            <input-text placeholder="303 Meadowview Drive" v-model="form.meadowviewDrive"/>
          </Validator>
        </div>
        <div class="item">
          <span>
            公司註冊國家
          </span>
        </div>
        <div class="item">
          <Validator type="drowDownMeun" defaultName="Select" :titleName="form.country" :show="chickShow" @isCheckOkValue="countryCheck">
            <select-menu :title="form.country" :width='250'>
              <option-menu v-for="item in countryList" :key="item.id" @click="countryName(item)" :fontSize="20">{{item.name}}</option-menu>
            </select-menu>
          </Validator>
        </div>
        <div class="item">
          <div class="buttomLeftItem">
            <div class="buttomitem">
              <span id="spanTittle1">
                業務類型
              </span>
            </div>
            <div class="buttomitem2">
              <Validator type="drowDownMeun" defaultName="Select" :titleName="form.organizationType" :show="chickShow" @isCheckOkValue="organizationTypeCheck">
                <select-menu :title="form.organizationType" :width='150'>
                  <option-menu v-for="item in organizationList" :key="item.id" @click="organizationName(item)" :fontSize="20">{{item.enName}}</option-menu>
                </select-menu>
              </Validator>
            </div>
          </div>
          <div class="buttomRightItem">
            <div class="buttomitem">
              <span id="spanTittle2">
                經紀類型
              </span>
            </div>
            <div class="buttomitem2">
              <Validator type="drowDownMeun" defaultName="Select" :titleName="form.brokerType" :show="chickShow" @isCheckOkValue="brokerTypeCheck">
                <select-menu :title="form.brokerType" :width='150'>
                  <option-menu v-for="item in brokerList" :key="item.id" @click="brokerName(item)" :fontSize="20">{{item.name}}</option-menu>
                </select-menu>
              </Validator>
            </div>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
import InputText from '@/components/XuComponents/form/InputText'
import SelectMenu from '@/components/selectMenu/SelectMenu'
import OptionMenu from '@/components/selectMenu/OptionMenu'
import {CountryList,getDictById} from "@/axios/api/dict"
import Validator from '@/components/validator/Validator'
export default {
  name:'AgencyFormTwo',
  components: {
    InputText,
    SelectMenu,
    OptionMenu,
    Validator,
  },
  props:{
    chickShow:{
      type:Boolean,
      default:false
    }
  },
  data() {
    return {
      countryList:[],
      organizationList:[],
      brokerList:[
        {
          name:"全披露",
        },
        {
          name:"非披露",
        },
      ],
      form:{
        meadowviewDrive:"",
        country:"Select",
        organizationType:"Select",
        brokerType:"Select",
      },
      isCheckAllOkForm:{
        meadowviewDriveCheck:false,
        countryCheck:false,
        organizationTypeCheck:false,
        brokerTypeCheck:false,
      },
      isFormOk:false,
    }
  },
  methods:{
    countryName(item){
      this.form.country = item.name
    },
    organizationName(item){
      this.form.organizationType = item.enName
    },
    brokerName(item){
      this.form.brokerType = item.name
    },
    countryNameListGet(){
      CountryList().then(res =>{
        this.countryList = res.data.items
      })
      getDictById(17000).then(res =>{
        this.organizationList = res.data.list
      })
    },
    meadowviewDriveCheck(CheckBoolen){
      this.isCheckAllOkForm.meadowviewDriveCheck = CheckBoolen
      this.changPage()
    },
    countryCheck(CheckBoolen){
      this.isCheckAllOkForm.countryCheck = CheckBoolen
      this.changPage()
    },
    organizationTypeCheck(CheckBoolen){
      this.isCheckAllOkForm.organizationTypeCheck = CheckBoolen
      this.changPage()
    },
    brokerTypeCheck(CheckBoolen){
      this.isCheckAllOkForm.brokerTypeCheck = CheckBoolen
      this.changPage()
    },
    changPage(){
      if(this.isCheckAllOkForm.meadowviewDriveCheck == true 
      && this.isCheckAllOkForm.countryCheck == true 
      && this.isCheckAllOkForm.organizationTypeCheck == true 
      && this.isCheckAllOkForm.brokerTypeCheck == true ){
        this.isFormOk = true
        this.$emit('isFormOk', this.isFormOk)
        this.$emit('form', this.form)
      }
      else{
        this.isFormOk = false
        this.$emit('isFormOk', this.isFormOk)
      }
    },
  },
  mounted(){
    this.countryNameListGet()
  }
}
</script>

<style scoped>
.agencyFormTwo{
  width: 100%;
  height: 100%;
}
.textBigBox{
  width: 372px;
  height: 100%;
  margin: 0 auto;
}
.textBox{
  width: 100%;
}
.item{
  width: 100%;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  margin: 0 0 14px 0;
}
.item:first-child{
  padding-top: 115px;
  margin: 0 0 14px 0;
}
.item:nth-child(3){
  margin: 0 0 47px 0;
}
.item span:last-child{
  font-size: 16px;
}
.item:nth-child(4) span{
  font-size: 24px;
}
.item:nth-child(4){
  margin: 0 0 17px 0;
}
.item:nth-child(5){
  margin: 0 0 60px 0;
}
.item:nth-child(6){
  display: flex;
}
.buttomLeftItem{
  flex: 1;
}
.buttomRightItem{
  flex: 1;
}
.buttomitem{
  margin: 0 0 17px 0;
}
#spanTittle1, #spanTittle2{
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
}
</style>
