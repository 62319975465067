<template>
  <div class="agencyFormSix">
    <div class="leftAndRightBox">
      <div class="spanBox">
        <span>省核中，請耐心等待。</span>
      </div>
      <div class="spanBox">
        <span>我們會盡快審核您的資料，歡迎隨時登錄賬號查詢進度。▶（這可能會花費2-5個工作日）</span>
      </div>
      <div class="spanBox">
        <div class="spanSmallBox">
          <div class="span">
            <span>{{sec}}秒後跳回首頁</span>
          </div>
          <div class="span" @click="goCenter">
            <span>直接跳轉</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name:'AgencyFormSix',
  components: {

  },
  data() {
    return {
      sec:5
    }
  },
  methods:{
    sendCode(){
      const countDown = setInterval(()=>{
        this.sec-- ;
        if(this.sec == 0){
          clearInterval(countDown);
          window.location.href = '/usercenter'
        }
      },1000);
    },
    goCenter(){
      window.location.href = '/usercenter'
    }
  },
  mounted() {
    this.sendCode()
  },
}
</script>

<style scoped>
.agencyFormSix{
  width: 100%;
  height: 100%;
  display: flex;
}
.leftAndRightBox{
  margin: 0 auto;
  height: 100%;
}
.spanBox{
  font-family: Inter;
  font-style: normal;
  font-weight: 700;
}
.spanBox:nth-child(1){
  margin: 0 auto;
  font-size: 64px;
  line-height: 64px;
  margin-bottom: 53px;
  margin-top: 167px;
}
.spanBox:nth-child(2){
  max-width: 600px;
  font-size: 24px;
  line-height: 28px;
  margin-bottom: 137px;
}
.spanBox:nth-child(3){
  display: flex;
  max-width: 600px;
}
.spanSmallBox{
  margin: 0 auto;
  display: flex;
}
.span:nth-child(1){
  margin-right: 42px;
  font-size: 24px;
  line-height: 28px;
}
.span:nth-child(2){
  font-size: 28px;
  line-height: 28px;
  color: #5E69C7;
  cursor: pointer;
}
</style>
